import React, { useState } from "react";
import { signupUrl } from "../../utils/urlHelper";
import {
  plans,
  featureGroups,
  features,
  products,
  productSubGroups,
} from "../../data/plans";
import { Button, Popover, TableStyles } from "components/ui";
import styled from "styled-components";
import tw from "tailwind.macro";
import filter from "lodash/filter";
import * as Ui from "components/ui";

/********************************************
 * Application Icons
 * *****************************************/
const getIconForApp = (appId) => {
  return (
    <Ui.Image
      filename={`brands/icons/${appId}-ico.png`}
      alt={appId}
      className="w-4 h-4"
    />
  );
};
const getIconForProduct = (key) => {
  return (
    <Ui.Image filename={`menu-${key}.png`} alt={key} className="w-4 h-4" />
  );
};
const ChevronIcon = ({ isCollapsed }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    style={{
      transform: isCollapsed ? "rotate(0deg)" : "rotate(180deg)",
      transition: "transform 0.2s ease-in-out",
    }}
  >
    <polyline points="18 15 12 9 6 15"></polyline>
  </svg>
);

/********************************************
 * Styling
 * *****************************************/
const SPlans = styled.div`
  ${tw`mt-16`}
  position: relative;
`;
const SPlanRow = TableStyles.STableRow;
const SPlanFeatures = TableStyles.STableBody;

const renderFeatureRow = (features) => {
  let output = [];

  features.map((feature) => {
    let featureOutput = [];
    let label = null;
    // Labels
    if (feature.helper.length > 1) {
      label = (
        <Popover content={feature.helper} className="inline font-light">
          {feature.label}
          {!!feature.new ? (
            <span
              className="ml-1 bg-green rounded uppercase text-white"
              style={{
                fontSize: "0.6rem",
                padding: "1px 4px",
              }}
            >
              New
            </span>
          ) : (
            ""
          )}
        </Popover>
      );
    } else {
      label = (
        <div className="font-light">
          {feature.label}
          {!!feature.new ? (
            <span
              className="ml-1 bg-green rounded text-white"
              style={{ fontSize: "0.55rem", padding: "1px 2px" }}
            >
              New
            </span>
          ) : (
            ""
          )}
        </div>
      );
    }

    featureOutput.push(
      <div className="w-full lg:w-5/12">
        <div className="py-3.5 text-sm sm:text-base">{label}</div>
      </div>
    );

    // Values
    let valuesCell = [];
    let plansToDisplay = plans;
    if (feature.plans?.one != null) {
      plansToDisplay = [{ id: "one" }];
    }
    plansToDisplay.map((plan, index) => {
      valuesCell.push(
        <div
          className={`flex items-center justify-center ${
            index === 0 && plansToDisplay.length > 1 ? "cell-highlight" : ""
          }`}
        >
          <div className="py-3.5 text-sm sm:text-base">
            {feature.plans[plan.id] === true ? (
              <i className="fa fa-check text-green"></i>
            ) : feature.plans[plan.id] === false ? (
              <>-</>
            ) : (
              <div
                className="font-semibold"
                dangerouslySetInnerHTML={{ __html: feature.plans[plan.id] }}
              />
            )}
          </div>
        </div>
      );
      return plan;
    });
    featureOutput.push(
      <div
        className={`w-full lg:w-7/12 grid grid-cols-${
          Object.keys(feature.plans).length
        }`}
      >
        {valuesCell}
      </div>
    );
    output.push(
      <SPlanRow className="flex flex-col lg:flex-row">{featureOutput}</SPlanRow>
    );

    return feature;
  });

  return output;
};

const PricingTable = (props) => {
  const [collapsedGroups, setCollapsedGroups] = useState({});
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [currentSection, setCurrentSection] = useState("All Features");
  const dropdownRef = React.useRef(null);

  /* ********************************************
   * Dropdown & Effects
   * *********************************************/
  // Close dropdown when clicking outside
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Track scroll position and update current section
  React.useEffect(() => {
    const handleScroll = () => {
      const sections = [
        ...document.querySelectorAll('[id^="feature-group-"]'),
        ...document.querySelectorAll('[id^="feature-subgroup-"]'),
      ];

      const header = document.querySelector(".sticky");
      const headerHeight = header ? header.offsetHeight : 0;

      // Find the section that's currently in view
      for (const section of sections) {
        const rect = section.getBoundingClientRect();
        if (rect.top <= headerHeight + 100 && rect.bottom >= headerHeight) {
          // Adding some buffer (100px)
          if (section.id.startsWith("feature-group-")) {
            const groupId = section.id.replace("feature-group-", "");
            const group = featureGroups.find((g) => g.id === groupId);
            if (group) setCurrentSection(group.label);
          } else {
            const subgroupId = section.id.replace("feature-subgroup-", "");
            const subgroup = productSubGroups[0].subgroups.find(
              (s) => s.id === subgroupId
            );
            if (subgroup) setCurrentSection(subgroup.label);
          }
          break;
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToGroup = (groupId) => {
    const element = document.getElementById(`feature-group-${groupId}`);
    const header = document.querySelector(".sticky");
    if (element && header) {
      const headerHeight = header.offsetHeight;
      const elementPosition =
        element.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - headerHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });

      setIsDropdownOpen(false);
      // Group label will be updated by scroll handler
      if (collapsedGroups[groupId]) {
        toggleGroup(groupId);
      }
    }
  };

  const scrollToSubgroup = (subgroupId) => {
    const element = document.getElementById(`feature-subgroup-${subgroupId}`);
    const header = document.querySelector(".sticky");
    if (element && header) {
      const headerHeight = header.offsetHeight;
      const elementPosition =
        element.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - headerHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });

      setIsDropdownOpen(false);
      // Subgroup label will be updated by scroll handler
      const parentFeature = features.find((f) => f.subgroup === subgroupId);
      if (parentFeature && collapsedGroups[parentFeature.group]) {
        toggleGroup(parentFeature.group);
      }
    }
  };

  const renderDropdown = () => {
    if (!isDropdownOpen) return null;

    const saasGroup = productSubGroups.find(
      (group) => group.productId === "saas"
    );

    return (
      <div className="absolute mt-2 w-64 bg-white rounded-lg shadow-lg border border-gray-100 py-2 z-50 text-sm">
        {products
          .filter((group) => !group.hideFromDropdown)
          .map((group) => {
            return (
              <button
                key={group.id}
                onClick={() => scrollToGroup(group.id)}
                className="w-full text-left px-6 py-2 hover:bg-gray-50 text-gray-700 flex items-center gap-3"
              >
                <span className="text-gray-500">
                  {getIconForProduct(group.id)}
                </span>
                {group.label}
              </button>
            );
          })}
        {saasGroup?.subgroups.map((subgroup) => (
          <button
            key={subgroup.id}
            onClick={() => scrollToSubgroup(subgroup.id)}
            className="w-full text-left px-6 py-2 hover:bg-gray-50 text-gray-700  flex items-center gap-3"
          >
            <span className="text-gray-500">{getIconForApp(subgroup.id)}</span>
            {subgroup.label}
          </button>
        ))}
      </div>
    );
  };

  const toggleGroup = (groupId) => {
    setCollapsedGroups((prev) => ({
      ...prev,
      [groupId]: !prev[groupId],
    }));
  };

  /* ********************************************
   * Rendering
   * *********************************************/
  const renderPlanHead = (plans, billingPeriod) => {
    let output = [];
    let planCells = [];

    plans.map((plan) => {
      planCells.push(
        <div className="p-4 sm:p-8 sm:px-12 flex flex-col items-center justify-center">
          <div className="w-full flex flex-col sm:flex-row sm:justify-between mb-4 text-center sm:text-left">
            <span className="text-xl sm:text-2xl font-extrabold mb-2 sm:mb-0">
              {plan["name"]}
            </span>
            <div>
              <span className="font-bold mr-1">
                $
                {props.billingPeriod === "MONTH"
                  ? plan["priceMonthly"]
                  : plan["priceYearly"]}
              </span>
              <span className="text-base">/m</span>
            </div>
          </div>
          <Button
            className="w-full block text-center text-sm sm:text-base"
            size="large"
            color={plan.id === "basic" ? "primary-border" : "primary"}
            href={signupUrl({
              sb_source: "website",
              sb_term: "pricing",
              sb_plan: plan.id,
            })}
          >
            {plan.id === "basic" ? "Get Started" : "Start Free Trial"}
          </Button>
        </div>
      );
      return plan;
    });

    output.push(
      <div className="text-dark">
        <div className="flex flex-col lg:flex-row">
          <div className="w-full lg:w-5/12 py-4 sm:py-8">
            <p className="text-xl sm:text-2xl font-extrabold mb-4 text-center lg:text-left">
              Compare Plans
            </p>
            <div className="flex flex-col sm:flex-row items-center gap-4 justify-center lg:justify-start">
              <div className="relative w-full sm:w-auto" ref={dropdownRef}>
                <button
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  className="w-full sm:w-auto flex items-center justify-between sm:justify-start gap-2 text-white px-4 py-3 rounded-full text-lg"
                  style={{
                    background: "linear-gradient(to bottom, #6998F7, #6267ED)",
                  }}
                >
                  <span className="text-base sm:text-lg font-medium">
                    {currentSection}
                  </span>
                  <ChevronIcon isCollapsed={isDropdownOpen} />
                </button>
                {renderDropdown()}
              </div>
              <Ui.Link
                to="calculator"
                underline={false}
                className="text-sm sm:text-base border-dotted border-b hidden sm:inline-block"
                style={{
                  color: "#6267ED",
                  borderColor: "#6267ED",
                  borderSpacing: "3px",
                }}
              >
                or customize your plan
              </Ui.Link>
            </div>
          </div>
          <div className="w-full lg:w-7/12 grid grid-cols-2 mt-4 lg:mt-0">
            {planCells}
          </div>
        </div>
      </div>
    );

    return output;
  };

  const renderPlanFeatures = (featureGroups, features) => {
    let output = [];
    featureGroups.map((group) => {
      let groupOutput = [];
      groupOutput.push(
        <div
          id={`feature-group-${group.id}`}
          className="w-full py-6 sm:py-8 sm:pt-12 text-dark"
        >
          <div
            className="flex items-center justify-between cursor-pointer px-4 sm:px-0"
            onClick={() => toggleGroup(group.id)}
          >
            <div>
              <p className="text-xl sm:text-2xl font-extrabold">
                {group.label}
              </p>
              <p className="text-gray-500 text-sm sm:text-base mt-2 max-w-2xl">
                {group.description}
              </p>
            </div>
            <div className="text-gray-400 hover:text-gray-600 ml-4">
              <ChevronIcon isCollapsed={collapsedGroups[group.id]} />
            </div>
          </div>
        </div>
      );

      output.push(<SPlanRow>{groupOutput}</SPlanRow>);

      if (!collapsedGroups[group.id]) {
        // Get all features of current group
        let groupFeatures = filter(
          features,
          (feature) => feature.group === group.id
        );

        // Group features by subgroup
        let subgroups = {};
        groupFeatures.forEach((feature) => {
          const subgroup = feature.subgroup || "main";
          if (!subgroups[subgroup]) {
            subgroups[subgroup] = {
              regular: [],
              addons: [],
            };
          }
          if (feature.addons) {
            subgroups[subgroup].addons.push(feature);
          } else {
            subgroups[subgroup].regular.push(feature);
          }
        });

        // Render features by subgroup
        Object.entries(subgroups).forEach(
          ([subgroup, { regular, addons }], index) => {
            if (subgroup !== "main") {
              const product = productSubGroups.find(
                (p) => p.productId === group.id
              );
              const subgroupObj = product?.subgroups.find(
                (sg) => sg.id === subgroup
              );
              const label = subgroupObj?.masterLabel || subgroupObj?.label;

              output.push(
                <SPlanRow>
                  <div
                    id={`feature-subgroup-${subgroup}`}
                    className="w-full"
                    style={{
                      marginTop: index === 0 ? "0" : "2rem",
                    }}
                  >
                    <div className="text-base pt-4 mb-1 text-dark font-bold py-2">
                      {label}
                    </div>
                  </div>
                </SPlanRow>
              );
            }

            // Render regular features
            if (regular.length > 0) {
              output.push(renderFeatureRow(regular));
            }

            // Render addons if they exist
            if (addons.length > 0) {
              output.push(
                <SPlanRow>
                  <div className="w-full">
                    <div className="text-base mb-1 text-dark font-bold pb-3.5 pt-5">
                      Optional Addons
                    </div>
                  </div>
                </SPlanRow>
              );
              output.push(renderFeatureRow(addons));
            }
          }
        );
      }

      return group;
    });

    return output;
  };

  return (
    <div {...props}>
      <SPlans>
        <div className="bg-gray-lighter sticky top-0 z-10">
          <Ui.Container>
            {renderPlanHead(plans, props.billingPeriod)}
          </Ui.Container>
        </div>
        <Ui.Container>
          <SPlanFeatures>
            {renderPlanFeatures(featureGroups, features)}
          </SPlanFeatures>
        </Ui.Container>
      </SPlans>
    </div>
  );
};

export default PricingTable;
