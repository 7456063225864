import React from "react";
import { signupUrl } from "../../utils/urlHelper";
import { plans } from "../../data/plans";
import { Button, Popover } from "components/ui";
import "./pricingQuick.scss";

const PricingQuick = (props) => {
  let planIndex = 0;
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-10">
      <div className="rounded-xl shadow-pricing flex flex-col border border-gray-200">
        <div className="p-8 flex flex-col items-center">
          <div className="rounded-full plan-label--gray py-1 px-6 inline uppercase text-base font-bold bg-gray-lighter text-primary">
            Basic
          </div>
          <div className="mt-8 text-lg">
            Start for free for 1 backup and pay as you go.
          </div>
          <div className="h-4 mt-6"></div>
          <div className="">
            <span className="font-black text-4xl">
              $
              {props.billingPeriod === "MONTH"
                ? plans[0].priceMonthly
                : plans[0].priceYearly}
            </span>
            <span className="opacity-75 ml-2">/mo</span>
          </div>
          <div className="button-container mt-6">
            <Button
              size="large"
              color="primary"
              href={signupUrl({
                sb_source: "website",
                sb_term: "pricing",
                sb_plan: "basic",
              })}
            >
              Get Started
            </Button>
          </div>
        </div>
      </div>

      <div className="rounded-xl shadow-pricing flex flex-col border primary-pricing">
        <div className="p-8 flex flex-col items-center">
          <div
            className="rounded-full py-1 px-6 inline uppercase text-base font-bold
            bg-white text-primary"
          >
            Plus
          </div>

          <div className="mt-8 text-lg">
            Flexibile scheduling, teams, compliance and more.
          </div>
          <div className="h-4 mt-6"></div>
          <div className="">
            <span></span>
            <span className="font-black text-4xl">
              $
              {props.billingPeriod === "MONTH"
                ? plans[1].priceMonthly
                : plans[1].priceYearly}
            </span>
            <span className="opacity-75 ml-2">/mo</span>
          </div>
          <div className="button-container mt-6">
            <Button
              size="medium"
              color="white"
              href={signupUrl({
                sb_source: "website",
                sb_term: "pricing",
                sb_plan: "plus",
              })}
            >
              Start Free Trial
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingQuick;
