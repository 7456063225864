import React, { useState } from "react";
import * as Ui from "components/ui";
import * as Sections from "components/sections";
import * as Layout from "components/layout";
import PricingTable from "./_pricingTable";
import PricingQuick from "./_pricingQuick";
import { TestimonialSection } from "components/sections";
import {
  Typography,
  Container,
  Section,
  Faqs,
  Row,
  SectionTitle,
} from "components/ui";
import { Button } from "components/ui";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import styled from "styled-components";
import tw from "tailwind.macro";
import { theme } from "../../../tailwind.config";
import "./pricingPage.scss";
import { signupUrl } from "../../utils/urlHelper";
import { plans } from "../../data/plans";

const SPeriodSwitcherButton = styled.div`
  ${tw`rounded-full px-2 sm:px-4 py-1 bg-gray-200 text-gray-600 text-sm sm:text-base w-full sm:w-auto text-center`}
  cursor: pointer;
  position: relative;
  &.active {
    ${tw`bg-white text-gray-800`}
  }
`;

const SPeriodSwitcher = styled.div`
  ${tw`rounded-full p-1 font-medium text-gray-500 w-full sm:w-auto`}
  background-color: #F9FAFB;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4px;
  @media (min-width: 640px) {
    display: inline-flex;
    justify-content: space-between;
    max-width: 325px;
  }
`;

const PricingPage = () => {
  const [billingPeriod, setBillingPeriod] = useState("MONTH");
  return (
    <Layout.Layout>
      <Layout.SEO refKey="pricing" />
      <Layout.HeroEmpty></Layout.HeroEmpty>

      <Ui.Section className="px-4 sm:px-0">
        <Ui.Container>
          <div className="flex flex-col md:flex-row md:mt-20 md:mb-36 md:gap-12">
            <div className="w-full md:w-5/12 flex flex-col gap-4 md:gap-6">
              <div>
                <h1 className="font-heading text-5xl sm:text-3xl md:text-4xl leading-tight md:leading-snug font-black text-dark">
                  Never worry about your backups again.
                </h1>
                <p className="leading-tight text-lg sm:text-xl mt-3 sm:mt-4">
                  Get Started for free.
                  <br />
                  No credit card required.
                </p>
              </div>

              <SPeriodSwitcher>
                <SPeriodSwitcherButton
                  className={billingPeriod === "MONTH" ? "active" : ""}
                  onClick={() => setBillingPeriod("MONTH")}
                >
                  Pay monthly
                </SPeriodSwitcherButton>
                <SPeriodSwitcherButton
                  className={billingPeriod === "YEAR" ? "active" : ""}
                  onClick={() => setBillingPeriod("YEAR")}
                >
                  Pay yearly{" "}
                  <span className="text-xs sm:text-sm text-gray-400">
                    save 20%
                  </span>
                </SPeriodSwitcherButton>
              </SPeriodSwitcher>

              <div className="text-center md:text-left">
                <Ui.Link arrow="right" to="calculator" underline={false}>
                  Customize your plan instantly
                </Ui.Link>
              </div>
            </div>
            <div className="w-full md:w-7/12 mt-6 md:mt-0">
              <PricingQuick
                className="mt-4 md:mt-8"
                billingPeriod={billingPeriod}
              />
            </div>
          </div>
        </Ui.Container>
      </Ui.Section>

      <PricingTable billingPeriod={billingPeriod} />

      <Ui.Section className="px-4 sm:px-0">
        <Ui.Container>
          <Ui.Row>
            <div className="rounded-xl p-4 sm:p-8 bg-gray-lighter mt-8 text-left">
              <span className="rounded-full plan-label py-1 px-3 inline uppercase text-sm font-bold tracking-wider bg-white">
                Enterprise
              </span>
              <div className="flex flex-col md:flex-row mt-6 justify-between gap-4">
                <div className="text-base sm:text-lg">
                  <p className="text-gray-600">
                    Looking for SLA, SSO, and compliance? Our Enterprise plans
                    can be customized based on your business needs.
                  </p>
                  <ul className="mt-3 grid grid-cols-2 gap-2">
                    <li className="flex items-start">
                      <i className="fa fa-check text-green mr-3 text-xs mt-1"></i>
                      <span>Custom contracts & policies</span>
                    </li>
                    <li className="flex items-start">
                      <i className="fa fa-check text-green mr-3 text-xs mt-1"></i>
                      <span>SAML Single-Sign On (SSO)</span>
                    </li>
                    <li className="flex items-start">
                      <i className="fa fa-check text-green mr-3 text-xs mt-1"></i>
                      <span>Yearly Invoicing Option</span>
                    </li>
                    <li className="flex items-start">
                      <i className="fa fa-check text-green mr-3 text-xs mt-1"></i>
                      <span>SLA for 99.99% Uptime</span>
                    </li>
                    <li className="flex items-start">
                      <i className="fa fa-check text-green mr-3 text-xs mt-1"></i>
                      <span>Custom integrations</span>
                    </li>
                    <li className="flex items-start">
                      <i className="fa fa-check text-green mr-3 text-xs mt-1"></i>
                      <span>Custom Addons & Limites</span>
                    </li>
                  </ul>
                </div>
                <div className="mt-4 md:mt-0">
                  <Button
                    className="w-full md:w-auto"
                    size="medium"
                    color="white"
                    href="/contact-us/"
                  >
                    Schedule a demo
                  </Button>
                </div>
              </div>
            </div>
          </Ui.Row>
        </Ui.Container>
      </Ui.Section>

      <Sections.SectionFaq
        color="alt"
        faqs={[
          "pricing_backup_resource_definition",
          "pricing_additional_backups",
          "basic_plan_pricing",
          "additional_storage",
          "refund_policy",
        ]}
      />

      <Sections.SectionTestimonialExtended />
    </Layout.Layout>
  );
};
export default PricingPage;
